<template>
  <div class="kpatable flex flex-col h-auto">
    <div class="w-full h-11 flex flex-row">
      <div class="row1 h-full">
        <span class="row1__text">.</span>
      </div>
      <div class="header_row row2 h-full">
        <span>KPA</span>
      </div>
      <div class="header_row row3 h-full">
        <span>Objectives</span>
      </div>
      <div class="header_row row4 h-full flex">
        <span>KPI</span>
      </div>
      <div class="header_row row5 h-full">
        <span>Weight (%)</span>
      </div>
    </div>
    <div class="kpatable__subheader w-full flex flex-row">
      <div
        class="subheader flex flex-row"
        style="width: 1200px; margin-left: 740px;"
      >
        <div class="subheader_row">
          0
        </div>
        <div class="subheader_row">
          1
        </div>
        <div class="subheader_row">
          2
        </div>
        <div class="subheader_row">
          3
        </div>
        <div class="subheader_row">
          4
        </div>
        <div class="subheader_row">
          5
        </div>
        <div class="subheader_row">
          6
        </div>
      </div>
      <div
        class="subheader flex flex-row"
        style="width: 204px;"
      />
    </div>
    <DetailedKPAList
      v-for="k in details"
      :key="k"
      :text="k.kpatext"
      :readonly="k.disabled"
      :objectives="k.objectives"
      :weight="k.weight"
    />
  </div>
</template>

<script>
import DetailedKPAList from '@/components/DetailedKPAList';

export default {
  name: "DetailedKPA",
  components: {
    DetailedKPAList,
  },
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.kpatable
{
  min-width: 120vw;
  overflow-x: auto;
  align-self: center;
}

.kpatable__header
{
    height: 46px;
    background-color: #FFFFFF;
}

.kpatable__subheader
{
  height: 25px;
  background-color: #EEEEEE;
  justify-content: flex-end;
}

.subheader
{
  padding: 4px;
}

.subheader_row
{
  width: 192px; height: 17px;
  font-weight: 600; font-size: 14px;
  text-align: center; letter-spacing: 0.015em;
  color: #321C3B;
}

.header_row
{
  padding: 10px 8px;
  margin: 4.5px 9px;
  font-size: 12px;
  font-weight: 900;
  line-height: 17px;
  letter-spacing: 0.035em;
  color: #878E99;
}

.row1
{
  width: 50px;
  margin: 0px 5px;
}

.row1__text
{
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.015em;
}

.row2
{
  width: 529px;
  height: 37px;
}

.row3
{
  width: 720px;
  height: 37px;
}

.row4
{
  width: 1817px;
  height: 37px;
  justify-content: center;
}

.row5
{
  width: 254px;
  height: 37px;
}

.kparow:nth-child(odd)
{
    background: #FFFFFF;
}

.kparow:nth-child(even)
{
  background: #F7F7FF;
}
</style>
